<template>
  <v-row>
    <v-col cols="12" sm="6">
      <VDatePicker
        v-model.string="calendarDate"
        :masks="masks"
        class="calendar"
        expanded
        :attributes="attributes"
      />
    </v-col>

    <v-col cols="12" sm="6">
      <div class="today-section">
        <h3 v-if="!dateToItems">Para hoy</h3>
        <h3 v-else>Para el día {{ dateToItems }}</h3>
        <v-list lines="one" v-if="itemsToday.length">
          <v-list-item
            v-for="(item, index) in itemsToday"
            :key="index"
            :title="item.title"
          >
            <div v-html="item.subtitle" class="subtitle-with-line-breaks"></div>
            <template v-slot:append>
              <v-btn
                icon="mdi-clock"
                variant="text"
                @click="stateAppointment(item)"
                v-if="item.estado"
              ></v-btn>
              <v-btn
                icon="mdi-thumb-up"
                variant="text"
                @click="stateAppointment(item)"
                v-if="!item.estado"
              ></v-btn>
              <v-btn
                icon="mdi-delete"
                variant="text"
                @click="deleteAppointment(item.id)"
              ></v-btn>
            </template>
          </v-list-item>
        </v-list>
        <span v-else>No hay registros de citas para la fecha seleccionada.</span>
      </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" sm="6">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-title color="primary">
            Nueva cita
            <template v-slot:actions="{ expanded }">
              <v-icon :icon="expanded ? 'mdi-pencil' : 'mdi-plus'"></v-icon>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-row justify="center">
              <v-col cols="12">
                <div class="text-center">
                  <VDatePicker
                    :min-date="new Date()"
                    expanded
                    v-model="date"
                    mode="date"
                    timezone="Europe/Amsterdam"
                  />
                  <VDatePicker
                    style="margin-top: 10px; margin-bottom: 10px"
                    v-model="date"
                    mode="time"
                    timezone="Europe/Amsterdam"
                  />
                  <v-text-field
                    v-model="text"
                    type="text"
                    label="Motivo cita"
                  ></v-text-field>
                  <v-autocomplete
                    v-model="clientToAppointment"
                    :items="clients"
                    :item-title="
                      (client) =>
                        `${client.nombre.toUpperCase()} | ${client.email} | ${
                          client.telefono
                        }`
                    "
                    item-value="id"
                    flat
                    hide-no-data
                    label="Cliente asociado a la cita"
                    prepend-inner-icon="mdi-account-search"
                    solo-inverted
                  ></v-autocomplete>
                  <v-btn
                    color="green"
                    variant="text"
                    @click="saveNewAppointment"
                    >Guardar</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, onMounted, toRaw, watch } from "vue";
import { useAppStore } from "@/store/app";
import genericReload from "./genericReload";
import * as basics from "./basics.js";
import { BASE_URL } from "./constants.js";

const store = useAppStore();

const attributes = ref([]);
const masks = ref({
  modelValue: "YYYY-MM-DD",
});
const calendarDate = ref(null);
const itemsToday = ref([]);
const date = ref(new Date());
let clients = ref(toRaw(store.clients));
const clientToAppointment = ref(null);
const text = ref("");
const dateToItems = ref(null);

const getAppointments = async () => {
  attributes.value = [];
  const response = await genericReload(
    store.auth,
    "api/appointment?fechaInicio=" + basics.getDate("month", -2)
  );
  if (response.status === 200) {
    const data = await response.json();
    for (let i = 0; i < data.length; i++) {
      const e = data[i];
      const fecha = data[i].fecha.split(" ")[0];
      const hora = data[i].fecha.split(" ")[1];
      attributes.value.push({
        dot: "yellow",
        popover: {
          label:
            hora +
            " | " +
            e.texto +
            (e.idCliente
              ? " | Cliente: " + store.clientById(e.idCliente).nombre
              : ""),
        },
        dates: [new Date(fecha)],
      });
    }
  }
};
const getToday = async (date) => {
  itemsToday.value = [];
  let query =
    "api/appointment?fechaInicio=" +
    basics.getDate() +
    "&fechaFin=" +
    basics.getDate();
  if (date) {
    query = "api/appointment?fechaInicio=" + date + "&fechaFin=" + date;
  }
  const response = await genericReload(store.auth, query);
  if (response.status === 200) {
    const data = await response.json();
    for (let i = 0; i < data.length; i++) {
      const e = data[i];
      const fecha = basics.formatDate(
        data[i].fecha.split(" ")[0],
        "dd/mm/yyyy"
      );
      const hora = data[i].fecha.split(" ")[1];
      itemsToday.value.push({
        id: data[i].id,
        title: hora.slice(0, 5),
        subtitle:
          e.texto +
          (e.estado ? " \n Estado: Aceptada" : " \n Estado: En espera") +
          (e.idCliente
            ? " \n Cliente: " + store.clientById(e.idCliente).nombre
            : ""),
        estado: e.estado,
      });
    }
  }
};

const saveNewAppointment = async () => {
  const postData = {
    idCliente: clientToAppointment.value,
    fecha: date.value,
    texto: text.value,
    estado: 1,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.auth,
  };

  const response = await fetch(`${BASE_URL}/api/appointment`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(postData),
  });

  switch (response.status) {
    case 200:
      clientToAppointment.value = null;
      text.value = "";
      date.value = new Date();
      await getToday();
      await getAppointments();
      break;
  }
};
const deleteAppointment = async (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.auth,
  };
  const response = await fetch(`${BASE_URL}/api/appointment/${id}`, {
    method: "DELETE",
    headers: headers,
  });
  switch (response.status) {
    case 200:
      await getToday();
      await getAppointments();
      break;
  }
};
const stateAppointment = async (item) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.auth,
  };
  const putData = {
    estado: !item.estado,
  };
  const response = await fetch(`${BASE_URL}/api/appointment/${item.id}`, {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(putData),
  });
  switch (response.status) {
    case 200:
      await getToday();
      await getAppointments();
      break;
  }
};

watch(calendarDate, (newValue) => {
  getToday(newValue);
  dateToItems.value = basics.formatDate(newValue, "dd/mm/yyyy");
});

onMounted(() => {
  getAppointments();
  getToday();
});
</script>

<style scoped>
.subtitle-with-line-breaks {
  white-space: pre-line; /* Esto hace que los saltos de línea sean visibles */
  font-size: 0.9em;
  color: grey;
}
.calendar {
  flex: 1;
  margin-right: 20px;
}

.today-section {
  margin-left: 30px;
  flex: 1;
  max-width: 400px;
}

.vc-time-picker {
  width: 100% !important;
}

@media (max-width: 768px) {
  .today-section {
    max-width: none;
    margin-left: 0px;
    margin-top: 30px;
  }
}

/* Para ajustar el ancho del panel de expansión en pantallas grandes */
@media (min-width: 768px) {
  .v-expansion-panel {
    max-width: 400px; /* Cambia el ancho según sea necesario */
  }
}

/* Para ajustar el ancho de los elementos internos (date pickers, autocomplete, etc.) en pantallas grandes */
@media (min-width: 768px) {
  .v-expansion-panel-text {
    max-width: 100%; /* Cambia el ancho según sea necesario */
  }
}
</style>
