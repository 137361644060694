import { createRouter, createWebHistory } from 'vue-router'
import { useAppStore } from '@/store/app';

const routes = [
  {
    path: '/',
    name: 'login',
    meta: {
      hideNavbar: true,
      title: "Login - doConnectCloud | Documentos compartidos entre empresas y clientes"
    },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/dir',
    name: 'dir',
    meta: {
      title: "Inicio - doConnectCloud | Documentos compartidos entre empresas y clientes"
    },
    component: () => import('../views/Dir.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    meta: {
      title: "Administración - doConnectCloud | Documentos compartidos entre empresas y clientes"
    },
    component: () => import('../views/Admin.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const store = useAppStore();
  document.title = to.meta.title || "doConnectCloud | Documentos compartidos entre empresas y clientes"
  if(to.name == 'RecoverPass'){
    next();
  }else{
    if (to.name !== 'login' && (store.auth=='' || store.auth==undefined)){
      next({ name: 'login' })
    }else{
      if((to.name == 'admin' && store.typeUser!='admin')){
        next({ name: 'login' })
      }else{
        if((to.name == 'dir' && store.typeUser!='user')){
          next({ name: 'login' })
        }else{
          next()
        }
      }
    }
  }
})

export default router
